.input {
    line-height: 1.7;
    border-radius: 5px;
    border-color: #0b1b26;
    color: #0b1b26;
    width: 100%;
    font-size: calc(10px + 2vmin);
    font-family: -apple-system, AlumniSansPinstripe, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    padding-left: 7px;
    padding-right: 16px;
    margin-bottom: 6px;
}

.invalidInput {
    border-color: #dc1a1a;
}
