.Button {
    background-color: #0b1b26;
    color: #ffffe7;
    cursor: pointer;
    border-radius: 0 5px 5px 0;
    border-color: #0b1b26;
    font-size: calc(8px + 2vmin);
    padding: 8px 12px 7px 12px;
    margin-bottom: 6px;
}

.onHover {
    background-color: #f9cc9f;
    color: #0b1b26;
}


.success {
    background-color: #74a860;
}
